@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/fonts';

.component {
  display: inline-flex;
  align-items: center;
  gap: spacings.$XXS;
  padding: spacings.$XS spacings.$S;
  border-radius: radii.$XS;
  background-image: linear-gradient(95deg, colors.$green300 33.34%, #11802c 104.95%);
  box-shadow:
    0px 4px 8px 0px rgba(2, 13, 39, 0.13),
    0px 4px 20px 0px rgba(2, 13, 39, 0.15);

  @include typography.labelMTypographyStyles;
  font-weight: 600;
  color: colors.$white;

  b {
    font-family: fonts.$campaignFontFamily;
    color: colors.$yellow200;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.34px;
  }
}

.text {
  text-align: center;
}

:export {
  colorYellow200: colors.$yellow200;
}
