@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';
@use '$styles/tools/extends/typography';

.component {
  border-radius: radii.$XS;
  display: inline-block;
  transform: rotate(var(--rotate-angle, -6deg));
  font-style: normal;
  background-image: linear-gradient(95deg, colors.$green300 33.34%, #11802c 104.95%);
  color: colors.$white;
  box-shadow:
    0px 4px 8px 0px rgba(2, 13, 39, 0.13),
    0px 4px 20px 0px rgba(2, 13, 39, 0.15);

  @include mediaqueries.largeUp {
    text-wrap: nowrap;
  }
  p {
    margin: 0;
  }
}
.medium {
  padding: spacings.$XXS spacings.$S;
  &,
  p {
    @extend %labelLTypographyStyles;
    font-weight: 600;
    letter-spacing: -0.18px;
    u {
      color: colors.$yellow300;
      @extend %labelSTypographyStyles;
      letter-spacing: -0.14px;
      text-decoration: line-through;
    }
  }
}
.large {
  &,
  p {
    padding: spacings.$XS spacings.$M;
    @extend %headlinesH5TypographyStyles;
    line-height: 24px;
  }
}
