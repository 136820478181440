@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';

.page {
  background-color: white;

  &:has(:global(.top)) {
    padding-top: 80px !important;
    margin-top: -80px;
  }
}

.section {
  padding-top: spacings.$M;

  @include mediaqueries.largeUp {
    padding-top: spacings.$XL;
  }

  :global(.Row) {
    row-gap: spacings.$XXL;
  }
}

.article {
  display: flex;
  flex-direction: column;
  gap: spacings.$XL;

  > :global(.RichTextArticle) {
    padding-top: 0;
  }

  h2 {
    @include typography.headlinesH5TypographyStyles;
    margin-bottom: 1em; // 24px

    @include mediaqueries.largeUp {
      @include typography.headlinesH4TypographyStyles;
    }
  }

  h3 {
    @include typography.labelLTypographyStyles;
    font-weight: 600;
    margin-bottom: 1.333334em; // 24px

    @include mediaqueries.largeUp {
      @include typography.headlinesH6TypographyStyles;
    }
  }

  h4,
  h5 {
    @include typography.labelMTypographyStyles;
    margin-bottom: 0.75em; // 12px

    @include mediaqueries.largeUp {
      @include typography.labelLTypographyStyles;
      font-weight: 600;
    }
  }

  p,
  ul,
  ol {
    @include typography.paragraphMTypography;

    &:last-child {
      margin-bottom: 2.5em; // 40px
    }

    // main heading inside the article must have top spacing
    & + h2 {
      margin-top: 1.666667em; // 40px
    }
  }

  ul,
  ol {
    padding-left: 1.5em;

    > li > p {
      margin: 1em 0 1em 0.3em;

      &:last-child {
        margin: 1em 0 1em 0.3em;
      }
    }
  }
}

.aside {
  display: flex;
  flex-direction: column;
  gap: spacings.$XXL;

  @include mediaqueries.upToMedium {
    > :global(.CmsVrmLookupTrust) {
      display: none;
    }
  }
}

.main {
  background-color: colors.$purple100;
}

.bottom {
  background-color: colors.$white;
}
